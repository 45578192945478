export class LocationMap {
  public locationTitle: string;
  public locationUrl: string;
  public titlePosX: number;
  public titlePosY: number;
  public titleCirclePosX: number;
  public titleCirclePosY: number;
  public isAnimate: boolean;
  public isBig: boolean;
  public isHidden: boolean;

  constructor(_locationTitle: string = '',
              _locationUrl: string = '',
              _titlePosX: number = -1,
              _titlePosY: number = -1,
              _titleCirclePosX: number = -1,
              _titleCirclePosY: number = -1,
              _isAnimate: boolean = false,
              _isBig: boolean = false,
              _isHidden: boolean = false) {
    this.locationTitle = _locationTitle;
    this.locationUrl = _locationUrl;
    this.titlePosX = _titlePosX;
    this.titlePosY = _titlePosY;
    this.titleCirclePosX = _titleCirclePosX;
    this.titleCirclePosY = _titleCirclePosY;
    this.isAnimate = _isAnimate;
    this.isBig = _isBig;
    this.isHidden = _isHidden;
  }
}

export const LocationsItems = [
  new LocationMap('Saaremaa', '/destinations/estonia/saaremaa/', 579, 438, -9, -4),
  new LocationMap('Riga', '/destinations/latvia/riga/', 580, 523, 40, -4),
  new LocationMap('Tallinn', '/destinations/estonia/tallinn/', 641, 385, -9, -4),

  new LocationMap('Gdansk', '/destinations/poland/gdansk/', 403, 653, 62, -4),

  new LocationMap('Rostock', '/destinations/germany/rostock/', 250, 676, -9, -4),
  new LocationMap('Sassnitz', '/destinations/germany/sassnitz/', 310, 646, -9, -4),
  // new LocationMap('Lübeck-Travemünde', '/destinations/germany/luebeck-travemuende/', 58, 678, 148, -4),

  new LocationMap('Oslo', '/destinations/norway/oslo/', 209, 366, -9, -4),
  new LocationMap('Arendal', '/destinations/norway/arendal/', 130, 468, -9, -4), //  !!!
  new LocationMap('Kristiansand', '/destinations/norway/kristiansand/', 16, 474, 88, -3),

  new LocationMap('Klaipeda', '/destinations/lithuania/klaipeda/', 563, 591, -9, -4),

  new LocationMap('Helsinki', '/destinations/finland/helsinki/', 646, 334, -9, -4),
  new LocationMap('Kotka', '/destinations/finland/kotka/', 729, 317, -9, -4),
  new LocationMap('Hamina', '/destinations/finland/hamina/', 745, 305, -9, -4),
  new LocationMap('Turku', '/destinations/finland/turku/', 564, 326, -9, -4),
  // new LocationMap('Kemi', '/destinations/finland/kemi/', 603, 14, -9, -4),
  new LocationMap('Mariehamn', '/destinations/finland/mariehamn/', 489, 351, -9, -4),

  new LocationMap('Stockholm', '/destinations/sweden/stockholm/', 433, 400, -9, -4),
  new LocationMap('Gothenburg', '/destinations/sweden/gothenburg/', 241, 480, -9, -4),
  new LocationMap('Lysekil', '/destinations/sweden/lysekil/', 236, 450, -9, -4),
  new LocationMap('Helsingborg', '/destinations/sweden/helsingborg/', 279, 561, -5, 9),
  new LocationMap('Karlskrona', '/destinations/sweden/karlskrona/', 384, 576, -9, -4),
  new LocationMap('Kalmar', '/destinations/sweden/kalmar/', 390, 546, -9, -4),
  new LocationMap('Malmö', '/destinations/sweden/malmoe/', 291, 589, -9, 5),
  new LocationMap('Ystad', '/destinations/sweden/ystad/', 315, 604, -9, -4),
  new LocationMap('Visby - Gotland', '/destinations/sweden/visby-gotland/', 450, 494, -9, -4),

  new LocationMap('Kalundborg', '/destinations/denmark/kalundborg/', 142, 591, 86, -4),
  new LocationMap('Roenne', '/destinations/denmark/roenne/', 344, 624, -9, -4),
  new LocationMap('Aalborg', '/destinations/denmark/aalborg/', 182, 528, -9, -4),
  new LocationMap('Helsingor', '/destinations/denmark/helsingor/', 207, 562, 45, 11),
  new LocationMap('Aarhus', '/destinations/denmark/aarhus/', 136, 570, 58, -4),
  new LocationMap('Skagen', '/destinations/denmark/skagen/', 139, 486, 62, -4),
  new LocationMap('Fredericia', '/destinations/denmark/fredericia/', 96, 605, 76, -4),
  new LocationMap('Copenhagen', '/destinations/denmark/copenhagen/', 224, 618, 33, -25),
  new LocationMap('Nyborg', '/destinations/denmark/nyborg/', 150, 620, 59, -4),
];

export const LocationsItemsLeftDot = [
  new LocationMap('Saaremaa', '/destinations/estonia/saaremaa/', 579, 438, -9, -4),
  new LocationMap('Riga', '/destinations/latvia/riga/', 630, 523, -9, -4),

  new LocationMap('Tallinn', '/destinations/estonia/tallinn/', 641, 385, -9, -4),

  new LocationMap('Gdansk', '/destinations/poland/gdansk/', 473, 653, -9, -4),

  new LocationMap('Rostock', '/destinations/germany/rostock/', 250, 676, -9, -4),
  new LocationMap('Sassnitz', '/destinations/germany/sassnitz/', 310, 646, -9, -4),
  new LocationMap('Lübeck-Travemünde', '/destinations/germany/luebeck-travemuende/', 219, 679, -9, -4),

  new LocationMap('Oslo', '/destinations/norway/oslo/', 209, 366, -9, -4),
  new LocationMap('Arendal', '/destinations/norway/arendal/', 130, 468, -9, -4),
  new LocationMap('Kristiansand', '/destinations/norway/kristiansand/', 116, 474, -9, -4),

  new LocationMap('Klaipeda', '/destinations/lithuania/klaipeda/', 566, 592, -9, -4),

  new LocationMap('Helsinki', '/destinations/finland/helsinki/', 646, 334, -9, -4),
  new LocationMap('Kotka', '/destinations/finland/kotka/', 729, 317, -9, -4),
  new LocationMap('Hamina', '/destinations/finland/hamina/', 745, 305, -9, -4),

  new LocationMap('Turku', '/destinations/finland/turku/', 564, 326, -9, -4),
  new LocationMap('Kemi', '/destinations/finland/kemi/', 603, 14, -9, -4),
  new LocationMap('Mariehamn', '/destinations/finland/mariehamn/', 489, 351, -9, -4),

  new LocationMap('Stockholm', '/destinations/sweden/stockholm/', 433, 400, -9, -4),
  new LocationMap('Gothenburg', '/destinations/sweden/gothenburg/', 241, 480, -9, -4),
  new LocationMap('Lysekil', '/destinations/sweden/lysekil/', 236, 450, -9, -4),

  new LocationMap('Helsingborg', '/destinations/sweden/helsingborg/', 281, 576, -9, -4),
  new LocationMap('Karlskrona', '/destinations/sweden/karlskrona/', 384, 576, -9, -4),
  new LocationMap('Kalmar', '/destinations/sweden/kalmar/', 390, 546, -9, -4),
  new LocationMap('Malmö', '/destinations/sweden/malmoe/', 291, 589, -9, 5),
  new LocationMap('Ystad', '/destinations/sweden/ystad/', 315, 620, -9, -4),
  new LocationMap('Visby', '/destinations/sweden/visby-gotland/', 452, 494, -9, -4),

  new LocationMap('Kalundborg', '/destinations/denmark/kalundborg/', 240, 591, -9, -4),
  new LocationMap('Roenne', '/destinations/denmark/roenne/', 344, 624, -9, -4),
  new LocationMap('Aalborg', '/destinations/denmark/aalborg/', 182, 528, -9, -4),
  new LocationMap('Helsingor', '/destinations/denmark/helsingor/', 271, 578, -9, -4),
  new LocationMap('Aarhus', '/destinations/denmark/aarhus/', 206, 570, -9, -4),
  new LocationMap('Skagen', '/destinations/denmark/skagen/', 211, 486, -9, -4),
  new LocationMap('Fredericia', '/destinations/denmark/fredericia/', 181, 605, -9, -4),
  new LocationMap('Copenhagen', '/destinations/denmark/copenhagen/', 174, 660, 33, -25),
  new LocationMap('Nyborg ', '/destinations/denmark/nyborg/', 232, 620, -9, -4),

  // new LocationMap('Kalinigrad', '', 532, 628, -9, -4)

];
