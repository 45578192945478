import {RelatedLinkModal} from './related-link.modal';
import {DestinationPreviewItem} from './destination-preview-item.modal';
import {NewsWidgetsModal} from './news-widgets.modal';
import {TransportationWidgetModal} from './transportations-widget.modal';
import {ContactInfoModal} from './contact-info-modal';

export class FrontpageModals {
  Home: HomeFrontpageModal;
  WhyCB: WhyCruiseFrontpageModal;
  Destinations: DestinationsFrontpageModal;
  PlannerTool: PlannerToolFrontpageModal;
  //TextSection: TextSectionFrontpageModal;
  ShorePowerInfo: TextSectionFrontpageModal;
  //Acc: TrasportationsHotels;
  //News: NewsFrontpageModel;
  //TrasportationsHotels: TrasportationsHotels;
  AllDestinations: AllDestinations;
  Discover: DiscoverVideo;
  Sustainability: any;
  News: any;

  constructor() {
    this.Home = new HomeFrontpageModal();
    this.WhyCB = new WhyCruiseFrontpageModal();
    this.Destinations = new DestinationsFrontpageModal();
    this.PlannerTool = new PlannerToolFrontpageModal();
    //this.TextSection = new TextSectionFrontpageModal();
    this.ShorePowerInfo = new TextSectionFrontpageModal();
    //this.Acc = new TrasportationsHotels();
    //this.News = new NewsFrontpageModel();
    //this.TrasportationsHotels = new TrasportationsHotels();
    this.AllDestinations = new AllDestinations();
    this.Discover = new DiscoverVideo();
  }
}

export class HomeFrontpageModal {
  Anchor: string;
  WidgetDescription: string;
  WidgetLink: RelatedLinkModal;
  WidgetTitle: string;
  Image: string;
  BackgroundVideo: VideoModel[];

  constructor() {
    this.Anchor = '';
    this.WidgetDescription = '';
    this.WidgetLink = new RelatedLinkModal();
    this.WidgetTitle = '';
    this.Image = '';
    this.BackgroundVideo = [];
  }
}

export class VideoModel {
  Extension: string;
  Url: string;

  constructor() {
    this.Extension = '';
    this.Url = '';
  }
}


export class WhyCruiseFrontpageModal {
  Anchor: string;
  Description: string;
  Header: string;
  Url: RelatedLinkModal;

  constructor() {
    this.Anchor = '';
    this.Description = '';
    this.Header = '';
    this.Url = new RelatedLinkModal();
  }
}

export class PlannerToolFrontpageModal {
  Anchor: string;
  Description: string;
  Header: string;
  IsVisible: boolean;
  Link: RelatedLinkModal;

  constructor() {
    this.Anchor = '';
    this.Description = '';
    this.Header = '';
    this.IsVisible = true;
    this.Link = new RelatedLinkModal();
  }
}

export class TextSectionFrontpageModal {
  Anchor: string;
  Description: string;
  Header: string;
  IsVisible: boolean;
  Link: RelatedLinkModal;
  Sustainability: any;
  News: any;
  constructor() {
    this.Anchor = '';
    this.Description = '';
    this.Header = '';
    this.IsVisible = true;
    this.Link = new RelatedLinkModal();
  }
}

export class DestinationsFrontpageModal {
  Anchor: string;
  Description: string;
  Header: string;
  DestinationsPreviews: DestinationPreviewItem[];

  constructor() {
    this.Anchor = '';
    this.Description = '';
    this.Header = '';
    this.DestinationsPreviews = [];
  }
}

export class NewsFrontpageModel {
  Anchor: string;
  Decription: string;
  Header: string;
  ButtonText: string;
  Widgets: NewsWidgetsModal[];

  constructor() {
    this.Anchor = '';
    this.Decription = '';
    this.ButtonText = '';
    this.Header = '';
    this.Widgets = [];
  }
}

export class TrasportationsHotels {
  Anchor: string;
  Description: string;
  Header: string;
  Teasers: TransportationWidgetModal[];

  constructor() {
    this.Anchor = '';
    this.Description = '';
    this.Header = '';
    this.Teasers = [];
  }
}

export class AllDestinations {
  AllDestination: AllDestination[];
  ContactInfo: ContactInfoModal;
  Anchor: string;

  constructor() {
    this.AllDestination = [];
    this.ContactInfo = new ContactInfoModal();
    this.Anchor = '';
  }
}

export class AllDestination {
  Items: DestinationPreviewItem[];
  Name: string;

  constructor() {
    this.Items = [];
    this.Name = '';
  }
}

export class DiscoverVideo {
  Anchor: string;
  Header: string;
  Link: RelatedLinkModal;
  Video: string;
  DiscoverVideo: string;
  VideoThumbnail: string;

  constructor() {
    this.Anchor = '';
    this.Header = '';
    this.Link = new RelatedLinkModal();
    this.Video = '';
    this.DiscoverVideo = '';
    this.VideoThumbnail = '';
  }

}
