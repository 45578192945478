import {
  AfterViewInit, Directive, ElementRef, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit,
  PLATFORM_ID
} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {LeftMenuService} from '../../components/left-menu/left-menu.service';
import {FooterService} from '../../services/footer.service';
import {isPlatformBrowser} from "@angular/common";
import set = Reflect.set;

@Directive({
  selector: '[appScrollListener]'
})
export class ScrollListenerDirective implements OnDestroy, OnChanges, AfterViewInit, OnInit {
  @Input() isBreakPageOnHalfSize: boolean;

  private masSections = [];
  private subscription: Subscription = new Subscription();
  private activePosition: number = 0;

  isBrowser;

  constructor(private elem: ElementRef,
              private leftMenuService: LeftMenuService,
              private footerService: FooterService,
              @Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit() {
    this.doScrollCheck();
  }

  ngOnInit() {
    this.subscription.add(this.leftMenuService.checkPositionEvent.subscribe(() => {
      setTimeout(() => {
        this.doScrollCheck();
      });
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges() {
    if (this.isBrowser) {
      this.reSize();
      let countScroll = 0;
      let i = 0;
      while (countScroll < window.scrollY - this.masSections[i + 1] / 2) {
        countScroll += this.masSections[i];
        i++;
      }
      this.activePosition = i;
      this.leftMenuService.changeMenuItemEvent.next(this.activePosition);
    }
  }

  reSize() {
    this.masSections = [];
    for (let i = 0; i < this.elem.nativeElement.children.length; i++) {
      if (this.elem.nativeElement.children[i].querySelector('section')) {
        this.masSections.push(this.elem.nativeElement.children[i].querySelector('section').offsetHeight);
      }
    }
  }

  @HostListener('window:scroll', [''])
  onScroll() {
    this.doScrollCheck();
  }

  public doScrollCheck() {
    if (this.isBrowser) {
      this.reSize();
      let countScroll = 0;
      let i = 0;
      const scrollYTop = window.scrollY || document.documentElement.scrollTop;
      if (this.isBreakPageOnHalfSize) {
        while (countScroll < scrollYTop - this.masSections[i + 1] / 2 + 100) {
          countScroll += this.masSections[i];
          i++;
        }

      } else {
        while (countScroll < scrollYTop + 100) {
          countScroll += this.masSections[i];
          i++;
        }
        i--;
      }
      if (scrollYTop < countScroll) {
        this.footerService.activeFooterInScroll = false;
        this.leftMenuService.setActiveMenuItem(i);
        if (this.activePosition !== i) {
          // i--;
          if (this.masSections.length - 1 === i) {
          }
          this.activePosition = i;
          this.leftMenuService.changeMenuItemEvent.next(this.activePosition);
        }
        this.leftMenuService.setHiddenDotEvent.next(false);
      } else {
        countScroll = 0;
        for (let j = 0; j < this.masSections.length; j++) {
          countScroll += this.masSections[j];
        }
        if (!this.footerService.activeFooterInScroll) {
          this.footerService.activeFooterInScroll = true;
          this.footerService.ScrollPositionSubscription.next(false);
          this.leftMenuService.setHiddenDotEvent.next(true);
        }
      }
    }
  }

}

