import { Directive,HostListener,Input } from '@angular/core';
import { Gtag } from 'angular-gtag';

@Directive({
  selector: '[eventTracker]'
})
export class GtagDirective {
  @Input('eventTracker') option:any;

  constructor(private gtag: Gtag) {}
  
  @HostListener('click', ['$event']) onClick($event){
    let type = this.formatType(this.option.type);
    let value = this.formatValue(this.option.value);
    debugger;
    this.gtag.event('click', {
        'type': type,
        'value': value
      });
  }

  formatType(type: string) {
    return type.replace(/[^a-zA-Z\s]/g, '').replace(/\s+/g, '_');
  }

  formatValue(value: string) {
    if (value) return value;
    else return '';
  }
}