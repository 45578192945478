import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Observable } from "rxjs";
import { ResourceUrlService } from "./resource-url.service";
import { PartnerIntranet } from "../classes/partner-intranet.model";
import { HttpClient } from "@angular/common/http";
@Injectable()
export class IntranetService {
  constructor(public httpService: HttpService, public httpClient: HttpClient) { }

  get(id: number, sort: string): Observable<any> {
    return this.httpService.get(ResourceUrlService.url + ResourceUrlService.getPartnerIntranetData + "?id=" + id + "&order=" + sort);
  }

  getPartnerIntranet() {
    return this.httpService.get(ResourceUrlService.url + ResourceUrlService.getPartnerIntranetData13);
  }

  updatePartnerIntranetCache() {
    return this.httpClient.get(ResourceUrlService.url + ResourceUrlService.updatePartnerIntranetCache13);
  }

  getIntranetItemById(intranet: PartnerIntranet, id: number) {
    if (id == -1) return null;
    if (intranet) {
      if (intranet.id == id) return intranet;
      for (let item of intranet.children) {
        if (item.id == id) return item;
        let foundItem = this.getIntranetItemById(item, id);
        if (foundItem != null) return foundItem;
      }
    }
    return null;
  }

  getIntranetItemsByParentId(intranet: PartnerIntranet, parentId: number) {
    if (intranet.id == parentId) return intranet.children;
    for (let item of intranet.children) {
      if (item.id == parentId) return item.children;
      let children = this.getIntranetItemsByParentId(item, parentId);
      if (children != null) return children;
    }
    return null;
  }

  filterFolders(intranetItems: PartnerIntranet[]) {
    return intranetItems.filter(x => x.type == "Folder");
  }

  filterFiles(intranetItems: PartnerIntranet[]) {
    return intranetItems.filter(x => x.type == "File");
  }
  filterImages(intranetItems: PartnerIntranet[]) {
    return intranetItems.filter(x => x.type == "Image");
  }
}
