import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class LoaderService {
  loading = false;
  timer: any = null;
  loadingSub = new BehaviorSubject<boolean>(true);


  show() {
    this.loading = true;
    this.loadingSub.next(true);
    if (this.timer != null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  hide() {
    this.loading = false;
    this.loadingSub.next(false);
  }

  getLoadingStatus(): Observable<boolean> {
    return this.loadingSub;
  }
}
