import { Component, Input, OnInit } from '@angular/core';
import { ResourceUrlService } from '../../../../services/resource-url.service';

@Component({
  selector: 'app-one-teaser-module',
  templateUrl: './one-teaser-module.component.html',
  styleUrls: ['./one-teaser-module.component.scss']
})
export class OneTeaserModuleComponent implements OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit() {
    
  }

  replaceMediaWithHost(text) {
    return text.replace('/media/', ResourceUrlService.url + '/media/');
  }

}
