import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {HeadersService} from "./headers.service";
import {Observable} from "rxjs";
import 'rxjs/Rx';
import {Router} from "@angular/router";
import {UserService} from "./user.service";
import {first} from "rxjs/operators";
@Injectable()
export class HttpService {
  constructor(public http: HttpClient,
              public headersService: HeadersService,
              public userService: UserService,
              public router: Router
  ) {

  }

  /**
   * GET
   * @param url
   * @returns {Observable<Response>}
   */
  public get(url: string): Observable<any> {
    let customHeaders = this.headersService.getHeaders();
    return this.http.get(url, {
      headers: customHeaders
    }).pipe(first()).catch((error: any) => {
      if(error.status == 401){
        this.userService.logout();
        this.router.navigate(["/"]);
      } else
        alert("Server Fejl");
      return Observable.throw(error.statusText);
    });
  }

  /**
   * POST
   * @param url
   * @param data
   * @returns {Observable<Response>}
   */
  public post(url, data) {
    let customHeaders = this.headersService.getHeaders();
    return this.http.post(url, data, {
      headers: customHeaders
    }).pipe(first()).catch((error: any) => {
      if(error.status == 401){
        // this.userService.role = null;
        // this.userService.user = null;
        this.headersService.token = "";
        this.router.navigate(["/"]);
      } else
        alert("Server Fejl");
      return Observable.throw(error.statusText);
    });
  }

  /**
   * PUT
   * @param url
   * @param data
   * @returns {Observable<Response>}
   */
  public put(url, data) {
    let customHeaders = this.headersService.getHeaders();
    return this.http.put(url, data, {
      headers: customHeaders
    }).pipe(first());
  }

  /**
   * DELETE
   * @param url
   * @returns {Observable<Response>}
   */
  public delete(url) {
    let customHeaders = this.headersService.getHeaders();
    return this.http.delete(url, {
      headers: customHeaders
    }).pipe(first());
  }
}
