import {Component, Input} from '@angular/core';
import { ResourceUrlService } from '../../../../services/resource-url.service';

@Component({
  selector: 'app-text-module',
  templateUrl: './text-module.component.html',
  styleUrls: ['./text-module.component.scss']
})
export class TextModuleComponent {
  @Input() data: any;
  @Input() breadCrumps: any;
  @Input() isFirstModule: boolean;

  replaceMediaWithHost(text) {
    return text.replace('/media/', ResourceUrlService.url + '/media/');
  }
}
