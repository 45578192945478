import {Component, ComponentRef, OnInit, ViewContainerRef} from '@angular/core';
import {
  IModalDialog, IModalDialogOptions, ModalDialogService,
  IModalDialogButton, IModalDialogSettings
} from 'ngx-modal-dialog';

@Component({
  selector: 'alert-modal-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements IModalDialog {


  public title: string = "";
  public description: string = "";


  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {

    let settings: IModalDialogSettings = {
      overlayClass: "modal-backdrop fade",
      overlayAnimationTriggerClass: "show",
      modalClass: "ngx-modal modal",
      modalAnimationTriggerClass: "lightbox-open",
      modalDialogClass: "modal-dialog",
      contentClass: "modal-content ",
      headerClass: "modal-header",
      headerTitleClass: "HeaderTitleClass",
      closeButtonClass: "close",
      closeButtonTitle: "close",
      bodyClass: "modal-body",
      footerClass: "modal-footer button-oval--container",
      alertClass: "alertClass",
      alertDuration: 5,
      buttonClass: "button-custom blue",
      notifyWithAlert: false
    };
    options.settings = settings;
    let data = options.data;
    this.title = data.title;
    this.description = data.description;
  }

}
