import {Component, ComponentRef, OnInit, ViewContainerRef} from '@angular/core';
import {
  IModalDialog, IModalDialogOptions, ModalDialogService,
  IModalDialogButton
} from 'ngx-modal-dialog';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit, IModalDialog {

  actionButtons: IModalDialogButton[];

  public title: string = "";
  private description: string = "";

  constructor(private modalService: ModalDialogService, private viewRef: ViewContainerRef) {
    this.actionButtons = [
      // { text: 'Close' }, // no special processing here
      // { text: 'I will always close', onAction: () => true },
      { text: 'Ok', onAction: () => true }
    ];
  }


  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    let opt = options as any;
    this.title = opt.title;
    // this.description = opt.description;
  }

  ngOnInit() {
  }

}
