import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: 'app/frontpage/frontpage.module#FrontpageModule'
  },
  {
    path: 'frontpage',
    loadChildren: 'app/frontpage/frontpage.module#FrontpageModule'
  },
  {
    path: 'profile',
    loadChildren: 'app/profile/profile.module#ProfileModule'
  },
  {
    path: 'destinations',
    loadChildren: 'app/destination/destination.module#DestinationModule'
  },
  {
    path: 'contacts',
    loadChildren: 'app/contacts/contacts.module#ContactsModule'
  },
  {
    path: 'events',
    loadChildren: 'app/events/events.module#EventsModule'
  },
  {
    path: 'gallery',
    loadChildren: 'app/gallery/gallery.module#GalleryModule'
  },
  {
    path: 'reports',
    loadChildren: 'app/modules/reports/reports.module#ReportsModule'
  },
  {
    path: 'press',
    loadChildren: 'app/modules/press/press.module#PressModule'
  },
  {
    path: 'cruiselines',
    loadChildren: 'app/modules/cruiselines/cruiselines.module#CruiselinesModule'
  },
  {
    path: 'recommendedhotels',
    loadChildren: 'app/modules/recommendedhotels/recommendedhotels.module#RecommendedhotelsModule'
  },
  {
    path: 'travelagents',
    loadChildren: 'app/modules/travelagents/travelagents.module#TravelagentsModule'
  },
  {
    path: 'travelagentsmain',
    loadChildren: 'app/modules/travelagentsmain/travelagentsmain.module#TravelagentsmainModule'
  },
  {
    path: 'welcometo',
    loadChildren: 'app/modules/welcometo/welcometo.module#WelcometoModule'
  },
  {
    path: 'brochures',
    loadChildren: 'app/modules/brochures/brochures.module#BrochuresModule'
  },
  {
    path: 'history',
    loadChildren: 'app/modules/history/history.module#HistoryModule'
  },
  {
    path: 'awards',
    loadChildren: 'app/modules/awards/awards.module#AwardsModule'
  },
  {
    path: 'port',
    loadChildren: 'app/portinfo/portinfo.module#PortinfoModule'
  },
  {
    path: 'allportinfo',
    loadChildren: 'app/allportinfo/allportinfo.module#AllportinfoModule'
  },
  {
    path: 'modularpages',
    loadChildren: 'app/modularpages/modularpages.module#ModularpagesModule'
  },
  {
    path: 'port-development-overview',
    loadChildren: 'app/dev-overview/dev-overview.module#DevOverviewModule'
  },
  {
    path: 'map',
    loadChildren: 'app/destinations-map/destinations-map.module#DestinationsMapModule'
  },
  {
    path: 'shore-power-map',
    loadChildren: 'app/shore-power-map/shore-power-map.module#ShorePowerMapModule'
  },
  {
    path: 'partner-intranet',
    loadChildren: 'app/partner-intranet/partner-intranet.module#PartnerIntranetModule'
  },
  // {
  //   path: 'pagenotfound',
  //   loadChildren: 'app/page-not-found/page-not-found.module#PageNotFoundModule'
  // },
  {path: 'cookie', loadChildren: 'app/cookie/cookie.module#CookieModule'},
  {path: '**', loadChildren: 'app/page-not-found/page-not-found.module#PageNotFoundModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
