import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResourceUrlService} from './resource-url.service';
import {hideloader} from '../../assets/js/loader';
import {Meta, Title} from '@angular/platform-browser';
import {first} from "rxjs/operators";
import {LoaderService} from './loader.service';

@Injectable()
export class ConfigService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private http: HttpClient,
              private meta: Meta,
              private loaderService: LoaderService,
              private titleService: Title) {
  }


  public getConfig(_configUrl: string): Promise<any> {
    this.loaderService.show();
    return this.http.get(ResourceUrlService.url + _configUrl).pipe(first()).toPromise().then((data) => {
      if (data['Meta']) {
        if (data['Meta'].Description !== '') {
          this.meta.updateTag({name: 'description', content: data['Meta'].Description});
        }
        if (data['Meta'].Keywords !== '') {
          this.meta.updateTag({name: 'keywords', content: data['Meta'].Keywords});
        }
        if (data['Meta'].NoIndex) {
          this.meta.updateTag({name: 'robots', content: 'noindex, nofollow'});
        }
         if (data['Meta'].Title) {
          this.titleService.setTitle(data['Meta'].Title);
         }
      }
      this.loaderService.hide();
      setTimeout(() => {
        hideloader();
      }, 1000);

      return data;
    });
  }

  public post(_data: any, _configUrl: string) {
    return this.http.post(ResourceUrlService.url + _configUrl, _data, this.httpOptions).pipe(first());
  }
}
