import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";
import {ResourceUrlService} from "./resource-url.service";
import {DestinationEditPage} from "../classes/destination-edit-page.model";
import {BehaviorSubject} from "rxjs";
import {RandomService} from "./random.service";
import {LoaderService} from "./loader.service";
import { DestinationEdit } from "../classes/destination-edit.model";
@Injectable()
export class DestinationEditService {

  layout: any;
  private _destination: DestinationEditPage = null;

  get destination(): DestinationEditPage {
    return this._destination;
  };

  set destination(value: DestinationEditPage) {
    this._destination = value;
    this.destinationEvent.next(RandomService.randomize(1000000, 9999999));
  }

  destinationEvent: BehaviorSubject<number> = new BehaviorSubject<number>(RandomService.randomize(1000000, 9999999));

  constructor(public httpService: HttpService,
              public loaderService: LoaderService) {

  }

  save(): Promise<any> {
    this.loaderService.show();
    // return new Promise<any>((resolve) => {
    //   resolve({Status: -10});
    // });
    this.duplicateShorePowerForAllPiers(this.destination.DestinationEdit);
    return this.httpService.post(ResourceUrlService.url + ResourceUrlService.postDestinationEdit, this.destination.DestinationEdit).toPromise().then(data => {
      this.destination = data;
      //this.reset();
      this.loaderService.hide();
      return data;
    })
  }

  duplicateShorePowerForAllPiers(destinationEdit: DestinationEdit) {
    if (destinationEdit.PortInformation.Sections.length > 0) {
      let firstShorePower = destinationEdit.PortInformation.Sections[0].ShorePower;
      let firstPierLayoutFiles = destinationEdit.PortInformation.Sections[0].PierLayoutFiles;
      if(firstShorePower && firstPierLayoutFiles) {
        destinationEdit.PortInformation.Sections.forEach(x => {
          x.ShorePower = [];
          x.PierLayoutFiles = [];
          firstPierLayoutFiles.forEach(f => {
            x.PierLayoutFiles.push(Object.assign({}, f));
          })
          firstShorePower.forEach(y => {
            x.ShorePower.push(Object.assign({}, y));
          });
        });
      }
    }
  }

  reset() {
    this.destination = null;
  }
}
