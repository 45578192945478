export class PopupWindowData {
  PopupTitle: string;
  PopupImage: string;
  PopupDescription: string;
  PopupLink: PopupLinkModel;

  constructor() {
    this.PopupTitle = '';
    this.PopupImage = '';
    this.PopupDescription = '';
    this.PopupLink = new PopupLinkModel();
  }
}

export class PopupLinkModel {
  Caption: string;
  Id: number;
  Url: string;
  NewWindow: string;

  constructor() {
    this.Caption = '';
    this.Id = -1;
    this.Url = '';
    this.NewWindow = '';
  }
}
