export class RelatedLinkModal {
  Caption: string;
  Id: number;
  NewWindow: boolean;
  Url: string;
  isMedia: boolean;

  constructor() {
    this.Id = -1;
    this.Caption = '';
    this.NewWindow = false;
    this.Url = '';
    this.isMedia = false;
  }
}
