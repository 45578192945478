import {Component, Input} from '@angular/core';
import {SeasonOption, seasonOptions} from '../../partner-intranet/entity/seasons';

@Component({
  selector: 'app-season-legend',
  templateUrl: './season-legend.component.html',
  styleUrls: ['./season-legend.component.scss']
})
export class SeasonLegendComponent {
  @Input() legend = 'Seasons:';
  @Input() showOnlyIcons = false;
  @Input() seasons: SeasonOption[] = seasonOptions;
}
