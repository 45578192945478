import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-modules-button',
  templateUrl: './modules-button.component.html',
  styleUrls: ['./modules-button.component.scss']
})
export class ModulesButtonComponent implements OnInit {

  @Input() data;

  constructor() {
  }

  ngOnInit() {
  }

  isItExternal(link: string) {
    if (link.includes('http://') || link.includes('https://')) return true;
    return false;
  }
}
