export class ContactInfoModal {
  Address: string;
  Email: string;
  Telephone: string;
  LinkedInLink: string;

  constructor() {
    this.Address = '';
    this.Email = '';
    this.Telephone = '';
    this.LinkedInLink = '';
  }
}
