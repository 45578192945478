import {Injectable} from '@angular/core';
import {ResourceUrlService} from './resource-url.service';

@Injectable()
export class ImageService {

  constructor() {
  }

  public getImageUrl(_url: string): string {
    if (_url && _url !== '') {
      return ResourceUrlService.url + _url;
    } else {
      return '';
    }
  }
}
