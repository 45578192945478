export class SeasonOption {
    name: string;
    selected: boolean;
    icon: string;
}

export const seasonOptions: SeasonOption[] = [
    { name: 'Jan-Mar', selected: false, icon: 'season-jan-mar' },
    { name: 'Apr-Jun', selected: false, icon: 'season-apr-jun' },
    { name: 'Jul-Sep', selected: false, icon: 'season-jul-sep' },
    { name: 'Oct-Nov', selected: false, icon: 'season-oct-nov' },
    { name: 'Dec', selected: false, icon: 'season-dec' }
];
