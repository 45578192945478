import {Injectable} from '@angular/core';
import {PreviousStateSroll} from '../classes/previous-state-sroll.model';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';

@Injectable()
export class ScrollPositionSaverService {
  scrollToAnchorObj: ScrollToConfigOptions = {
    target: '',
    offset: -80
  };

  private _previousScrollPosition: PreviousStateSroll = null;
  get previousScrollPosition(): PreviousStateSroll {
    return this._previousScrollPosition;
  }

  set previousScrollPosition(psp: PreviousStateSroll) {
    if (!this._previousScrollPosition || psp == null)
      this._previousScrollPosition = psp;
  }


  constructor(private scrollToService: ScrollToService) {
  }

  public scrollToAnchor(destination: string) {
    if (this.previousScrollPosition && this.previousScrollPosition.adminInfo.Destination === destination) {
      setTimeout(() => {
        this.scrollToAnchorObj['target'] = this._previousScrollPosition.activeMenuItem.anchorId;
        if (this.scrollToAnchorObj) {
          this.scrollToService.scrollTo(this.scrollToAnchorObj);
          this.previousScrollPosition = null;
        }
      }, 300);
    } else {
      this.previousScrollPosition = null;
    }
    if ('dev-overview' === destination) {
      setTimeout(() => {
        this.scrollToAnchorObj['target'] = this._previousScrollPosition.activeMenuItem.anchorId;
        if (this.scrollToAnchorObj) {
          this.scrollToService.scrollTo(this.scrollToAnchorObj);
          this.previousScrollPosition = null;
        }
      }, 300);
    } else {
      this.previousScrollPosition = null;
    }
  }
}
