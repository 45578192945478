export class ResourceUrlService {
 // public static url: string = 'https://api.cruisebaltic.com'; // live
  // public static url: string = 'https://api.live.cruisebaltic.ovdal.dk'; // new live
  //public static url: string = 'https://api.stage.cruisebaltic.ovdal.dk/'; // stage
  public static url: string = 'https://api.new.stage.cruisebaltic.ovdal.dk'; // new stage
  // public static url: string = 'http://localhost:30476'; //old localhost
  //  public static url: string = 'https://localhost:44390'; //new localhost

  public static getLogin: string = '/api/Login/Login';
  public static getLogin13: string = '/umbraco/api/auth/login';
  public static getLogOut: string = '/api/Login/LogOut';


  public static getFooterData: string = '/api/Menu/GetFooterData';
  public static getFooterData13: string = '/umbraco/api/Layout/GetFooter';
  public static getFrontpageData: string = '/api/Frontpage/GetFrontpageData';
  public static getFrontpageData13: string = '/umbraco/api/Frontpage/GetFrontpageData';
  public static getCommonData: string = '/api/Menu/GetMenuData';
  public static getCommonData13: string = '/umbraco/api/Layout/GetHeader';
  public static getDestinationData: string = '/api/Destination/GetDestinationData';
  public static getDestinationData13: string = '/umbraco/api/Destination/GetDestinationData';
  public static getPressData: string = '/api/ContentPage/GetPressFolderData';
  public static getPressData13: string = '/umbraco/api/Press/GetPressFolderData';
  public static getPressItemData: string = '/api/ContentPage/GetPresItem';
  public static getPressItemData13: string = '/umbraco/api/Press/GetPressItem';
  public static getModularPageData: string = '/api/ContentPage/GetContentPageData';
  public static getModularPageData13: string = '/umbraco/api/ContentPage/GetContentPageByUrl';
  public static getDestinationEditData: string = '/api/Destination/GetDestinationEditData/';
  public static getDestinationEditData13: string = '/umbraco/api/Destination/GetDestinationEditData/';
  public static getPartnerIntranetData: string = '/api/Destination/GetPartnerIntranetData';
  public static getPartnerIntranetData13: string = '/umbraco/api/Intranet/PartnerIntranet';
  public static updatePartnerIntranetCache13: string = '/umbraco/api/Intranet/UpdatePartnerIntranetCache';
  public static GetPortDevelopmentsPageData: string = '/api/PortDevelopmentsPage/GetPortDevelopmentsPageData';
  public static GetPortDevelopmentsPageData13: string = '/umbraco/api/PortDevelopmentsPage/GetPortDevelopmentsPageData';
  public static getContactsData: string = '/api/ContentPage/GetContactsPageData/';
  public static getContactsData13: string = '/umbraco/api/Contacts/GetContacts/';
  public static getGalleryData: string = '/api/ContentPage/GetGalleryData';
  public static getAboutData: string = '/api/ContentPage/GetAboutPageData';
  public static getPortInfoData: string = '/api/PortInfo/GetPortInfoPageData';
  public static getPortDetailData: string = '/api/PortInfo/GetPortInfoDestinationData';
  public static getPortDetailData13: string = '/umbraco/api/PortInfo/GetPortInfoDestinationData';
  public static getAllPortInfoData: string = '/api/PortInfo/GetAllPortInfoPageData';
  public static getAllPortInfoData13: string = '/umbraco/api/PortInfo/GetAllPortInfoPageData';
  public static getEventsData: string = '/api/ContentPage/GetEventsPageData';

  public static postEventsData: string = '/api/ContentPage/PostEventsPageData';
  public static postPortInfoData: string = '/api/PortInfo/GetPortInfoFilteredData';
  public static postDestinationEdit: string = '/api/Destination/PostDestinationEditData';
  public static postAllPortInfoData: string = '/api/PortInfo/GetAllPortInfoFilteredData';
  public static postAllPortInfoData13: string = '/umbraco/api/PortInfo/GetAllPortInfoFilteredData';
  public static getPortReceptionFacilitiesPageData: string = '/api/PortReceptionFacilities/GetPortReceptionFacilitiesPageData';
  public static getPortReceptionFacilitiesDestinationPageData: string = '/api/PortReceptionFacilities/GetPortReceptionFacilitiesDestinationPageData/';
  public static getPopupWindowData: string = '/api/General/GetPopupWindowData';

  public static postSaveProperty = "/umbraco/api/destination/save";
  public static postSaveImage = "/umbraco/api/destination/saveImage";
  public static postSaveImages = "/umbraco/api/destination/saveImages";
  public static postSaveFile = "/umbraco/api/destination/saveFile";
  public static postSaveFiles = "/umbraco/api/destination/saveFiles";

  public static getDestinationPagePdf: string = '/mvc/Pdf/DestinationPagePdf/';
  public static getAllPoertInfoExcel: string = '/mvc/Excel/GetExcel';
  public static getAllPortInfoShorePower: string = '/api/PortInfo/GetAllPortInfoShorePower';
  public static getAllPortInfoShorePower13: string = '/umbraco/api/PortInfo/GetAllPortInfoShorePower';
}
