import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ImageItem} from '../../classes/gallery-model';
import {ActivatedRoute, Router} from '@angular/router';
import {ScrollToConfigOptions, ScrollToService} from "@nicky-lenaers/ngx-scroll-to";
import {Subscription} from "rxjs/Subscription";

@Component({
  selector: 'app-pagination-nav',
  templateUrl: './pagination-nav.component.html',
  styleUrls: ['./pagination-nav.component.scss']
})
export class PaginationNavComponent implements OnInit, OnChanges, OnDestroy {

  @Input() allPaginationItems: any[];
  @Input() itemsPerPage: number;
  @Input() activeIndex: number;
  @Input() classConfig: any;
  @Input() activeRoute: string;
  @Input() queryString: string;
  @Input() anchorNavigation: string;

  @Output() changedPage: EventEmitter<any[]> = new EventEmitter();
  public itemsInPaginationMenu: number = 5;
  public activeArrayPaginationMenu: number[] = [];

  scrollToTop: ScrollToConfigOptions = {
    target: ''
  };

  private maxNumberPage: number = 0;

  private subscription: Subscription = new Subscription();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private _scrollToService: ScrollToService) {
  }

  ngOnInit() {
    this.scrollToTop = {target: this.anchorNavigation};
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  ngOnChanges() {
    this.calculateSizesPagination();
  }

  public goToNext() {
    if (this.activeIndex + 1 <= this.maxNumberPage) {
      this.activeIndex++;
      this.router.navigateByUrl(this.activeRoute + '/' + this.activeIndex + this.queryString);
      this.calculateSizesPagination();
    }
    if (this.scrollToTop) {
      this._scrollToService.scrollTo(this.scrollToTop);
    }
  }

  public goToPrev() {
    if (this.activeIndex - 1 > 0) {
      this.activeIndex--;
      this.router.navigateByUrl(this.activeRoute + '/' + this.activeIndex + this.queryString);
      this.calculateSizesPagination();
    }
    if (this.scrollToTop) {
      this._scrollToService.scrollTo(this.scrollToTop);
    }
  }

  public goToSelected(_index: number) {
    this.activeIndex = _index;
    this.queryString = this.queryString ? this.queryString : '';
    this.router.navigateByUrl(this.activeRoute + '/' + this.activeIndex + this.queryString);
    this.calculateSizesPagination();
    if (this.scrollToTop) {
      this._scrollToService.scrollTo(this.scrollToTop);
    }
  }

  private calculateSizesPagination() {
    this.subscription.add(this.route.params.subscribe(params => {
      if (!+params['pageId']) {
        if (this.router.url.indexOf(params['pageId']) === -1) {
          return;
        } else {
          this.router.navigate([this.activeRoute, this.activeIndex]);
        }
      }
    }));

    this.activeArrayPaginationMenu = [];
    if (this.allPaginationItems) {
      this.maxNumberPage = Math.ceil(this.allPaginationItems.length / this.itemsPerPage);
      for (let i = 0; i < this.itemsInPaginationMenu; i++) {
        this.activeArrayPaginationMenu[i] = this.activeIndex - 2 + i;
      }

      this.formatRightRuleArray();
    }
  }

  private formatRightRuleArray() {
    if (this.allPaginationItems.length === 0) {
      this.activeArrayPaginationMenu = [];
      this.emitNumberItems();
      return;
    }

    for (let i = 0; i < this.itemsInPaginationMenu; i++) {
      if (this.activeArrayPaginationMenu[i] <= 0) {
        this.removeFromStartArrayPagination();
        this.addToEndArrayPagination();
        i--;
      } else {
        break;
      }
    }

    for (let i = this.itemsInPaginationMenu - 1; i > 0; i--) {
      if (this.activeArrayPaginationMenu[i]) {
        if (this.activeArrayPaginationMenu[i] > this.maxNumberPage) {
          this.removeFromEndArrayPagination();
          this.addToStartArrayPagination();
          i++;
        } else {
          break;
        }
      }
    }

    if (this.activeArrayPaginationMenu.indexOf(this.activeIndex) < 1) {
      if (this.activeIndex - 1 > 0) {
        if (this.addToStartArrayPagination()) {
          this.removeFromEndArrayPagination();
        }
      }
    }

    if (this.activeArrayPaginationMenu.indexOf(this.activeIndex) < 2) {
      if (this.activeIndex - 1 > 0) {
        if (this.addToStartArrayPagination()) {
          this.removeFromEndArrayPagination();
        }
      }
    }

    if (this.activeArrayPaginationMenu.indexOf(this.activeIndex) > 2) {
      if (this.activeIndex + 1 <= this.maxNumberPage) {
        if (this.addToEndArrayPagination()) {
          this.removeFromStartArrayPagination();
        }
      }
    }

    if (this.activeArrayPaginationMenu.indexOf(this.activeIndex) > 3) {
      if (this.activeIndex + 1 <= this.maxNumberPage) {
        if (this.addToEndArrayPagination()) {
          this.removeFromStartArrayPagination();
        }
      }
    }

    this.emitNumberItems();
  }

  private emitNumberItems() {
    const tempMas: ImageItem[] = [];
    for (let i = 0; i < this.itemsPerPage; i++) {
      if (this.allPaginationItems[(this.activeIndex - 1) * this.itemsPerPage + i]) {
        tempMas.push(this.allPaginationItems[(this.activeIndex - 1) * this.itemsPerPage + i]);
      }
    }

    this.changedPage.emit(tempMas);
  }

  private addToEndArrayPagination() {
    const value = this.activeArrayPaginationMenu[this.activeArrayPaginationMenu.length - 1] + 1;
    if (value <= this.maxNumberPage) {
      this.activeArrayPaginationMenu.push(value);
      return true;
    } else {
      return false;
    }
  }

  private addToStartArrayPagination(): boolean {
    const value = this.activeArrayPaginationMenu[0] - 1;
    if (value > 0) {
      this.activeArrayPaginationMenu.unshift(value);
      return true;
    } else {
      return false;
    }
  }

  private removeFromStartArrayPagination() {
    if (this.activeArrayPaginationMenu[0] || this.activeArrayPaginationMenu[0] === 0) {
      this.activeArrayPaginationMenu.shift();
    }
  }

  private removeFromEndArrayPagination() {
    if (this.activeArrayPaginationMenu[this.activeArrayPaginationMenu.length - 1]) {
      this.activeArrayPaginationMenu.pop();
    }
  }

}
