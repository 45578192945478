import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {StorageService} from "./storage.service";
import {RandomService} from "./random.service";
import {HeadersService} from "./headers.service";
import {LoginResponseModel} from "../classes/login.model";
import { Router } from "@angular/router";
import { HttpHeaders } from "@angular/common/http";
import { LoaderService } from "./loader.service";
@Injectable()
export class UserService{

  private readonly LOCAL_STORAGE_KEY_USER = 'application_user_cruise-baltic';
  private readonly LAST_REQUEST_KEY = 'lastRequestTime';

  public userChange: Subject<number> = new Subject<number>();
  private _user: LoginResponseModel = null;

  public get user(): LoginResponseModel {
    // if(this.isUserLoggedInMoreThen2HoursAgo()) {
    //   this.logout();
    //   this.loader.hide();
    //   this.router.navigate(['/']);
    // } else {
      if(this._user == null) this._user = this.storageService.get(this.LOCAL_STORAGE_KEY_USER, true);
      return this._user;
    //}
  }

  public set user(u: LoginResponseModel) {
    this._user = u;
    this.headersService.token = u.Token;
    this.storageService.set(this.LOCAL_STORAGE_KEY_USER, u, true);
    this.storageService.set(this.LAST_REQUEST_KEY, new Date().toString(), false);
    this.userChange.next(RandomService.randomize(1000000, 9999999));
  }

  public getUser(): LoginResponseModel {
    return this.storageService.get(this.LOCAL_STORAGE_KEY_USER, true);
  }

  constructor(public headersService: HeadersService,
              public storageService: StorageService,
              public router: Router,
              private loader: LoaderService){

  }

  
  logout(){
    this._user = null;
    this.headersService.token = null;
    this.storageService.remove(this.LOCAL_STORAGE_KEY_USER);
    this.storageService.remove(this.LAST_REQUEST_KEY);
    this.userChange.next(RandomService.randomize(1000000, 9999999));
  }

  getAuthHeader(){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  this.user.Token)
    }
    return header;
  }
}
