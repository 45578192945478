import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-centered-text',
  templateUrl: './centered-text.component.html',
  styleUrls: ['./centered-text.component.scss']
})
export class CenteredTextComponent implements OnInit {
  @Input() data: any;
  @Input() breadCrumps: any;
  @Input() isFirstModule: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
