import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-topbaner',
  templateUrl: './topbaner.component.html',
  styleUrls: ['./topbaner.component.scss']
})
export class TopbanerComponent implements OnInit {
  @Input() data: any;
  @Input() breadCrumps: any;

  constructor() {
  }

  ngOnInit() {
  }

}
