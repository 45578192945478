import {Pipe, PipeTransform} from '@angular/core';
import {ResourceUrlService} from '../services/resource-url.service';

@Pipe({
  name: 'imageHost',
  pure: true
})
export class ImageHostPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value && value !== '') {
      if (value[0] != '/') value = '/' + value;
      return ResourceUrlService.url + value;
    } else {
      return '';
    }
  }

}
