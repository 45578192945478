import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';

import {HeaderService} from './services/header.service';
import {LeftMenuService} from './components/left-menu/left-menu.service';
import {ConfigService} from './services/config.service';
import {ImageService} from './services/image.service';
import {SharedModule} from './components/shared.module';
import {HttpService} from './services/http.service';
import {DestinationEditService} from './services/destination-edit.service';
import {HeadersService} from './services/headers.service';
import {UserService} from './services/user.service';
import {LoaderService} from './services/loader.service';
import {IntranetService} from './services/intraniet.service';
import {FooterService} from './services/footer.service';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {StorageService} from './services/storage.service';
import {ScrollToTopService} from './services/scroll-to-top.service';
import {ScrollPositionSaverService} from './services/scroll-position-saver.service';
import {SeoHelperService} from './services/seo-helper-service.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GtagDirective } from './directives/gtag/gtag.directive';
import { TimeoutInterceptor } from './interceptors/timeout.interceptor';
import { GtagModule } from 'angular-gtag';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'cruise-baltic' }),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    AngularSvgIconModule,
    GtagModule.forRoot({ trackingId: 'GTM-K45W3PFM', trackPageviews: true })
    //LottieAnimationViewModule.forRoot(),
  ],
  providers: [
    HeaderService,
    LeftMenuService,
    ConfigService,
    ImageService,
    HttpService,
    HeadersService,
    DestinationEditService,
    UserService,
    LoaderService,
    IntranetService,
    FooterService,
    StorageService,
    ScrollToTopService,
    ScrollPositionSaverService,
    SeoHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    // @Inject(PLATFORM_ID) private platformId: Object,
    // @Inject(APP_ID) private appId: string
  ) {
    // const platform = isPlatformBrowser(platformId) ?
    //   'in the browser' : 'on the server';
    // console.log(`Running ${platform} with appId=${appId}`);
  }
}
