import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener, Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, PLATFORM_ID,
  ViewChild
} from '@angular/core';

import {LeftMenuItem} from '../../classes/left-menu-item';

import {LeftMenuService} from './left-menu.service';
import {HeaderService} from '../../services/header.service';
import {SectionMegaMenu} from '../../classes/data-header.model';
import {Subscription} from 'rxjs/Subscription';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import set = Reflect.set;
import {HttpService} from '../../services/http.service';
import {ResourceUrlService} from '../../services/resource-url.service';
import {LoaderService} from '../../services/loader.service';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {isPlatformBrowser} from '@angular/common';
import { HeaderMenuColumn } from '../../classes/data-header-new.model';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('menuLeftWave') waveTop;
  @ViewChild('mainMenu') mainMenu;
  @ViewChild('hamburgerButton') hamburgerButton;

  @Input() routeName: string;
  @Input() profilePage: boolean;

  public href: string = '';
  public dataHeader: HeaderMenuColumn[];
  public isActiveLeftMenu: boolean;
  public listMenuItems: LeftMenuItem[] = [];
  public waveMoveConst = 40;
  public oversightPoint = 0;
  public topPosition;
  public isMenuItems = true;
  public isHiddenDot: boolean = false;
  private activeIndexMenuItem = 0;
  private disableClickOnLeftMenuItem: boolean = true;
  private temporaryValueForDot: boolean = true;
  private goToDevOverview;
  private goToDevOverviewResult;
  private timeout;

  private scrollToTop: ScrollToConfigOptions = {
    target: 'header'
  };

  // changeMenuItemEvent: Subscription = null;
  // setMenuDataEvent: Subscription = null;
  // setLeftNavigationEvent: Subscription = null;
  // changeDisableClickOnLeftMenuItem: Subscription = null;
  // setHiddenDotEvent: Subscription = null;

  showLoginButton: boolean = true;

  isIeMenu: boolean = false;
  isBrowser;

  private subscription: Subscription = new Subscription();

  constructor(private leftMenuService: LeftMenuService,
              private headerService: HeaderService,
              private router: Router,
              private cdRef: ChangeDetectorRef,
              public userService: UserService,
              public httpService: HttpService,
              public loaderService: LoaderService,
              private _scrollToService: ScrollToService,
              @Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.isIeMenu = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
    if (this.userService.user != null) {
      this.showLoginButton = false;
    }
    this.subscription.add(this.userService.userChange.subscribe(() => {
      if (this.userService.user != null) {
        this.showLoginButton = false;
      } else {
        this.showLoginButton = true;
      }
    }));

    // setTimeout(() => {
    this.listMenuItems = this.leftMenuService.getMenuItems();


    // scrollto dev Oveview

    this.goToDevOverview = this.leftMenuService.goToDevOverviewState.subscribe((state) => {
      this.goToDevOverviewResult = state;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      if (this.goToDevOverviewResult) {
        this.timeout = setTimeout(() => {
          if (this.listMenuItems) {

            for (let i = 0; i < this.listMenuItems.length; i++) {
              if (this.listMenuItems[i].anchorId === 'dev-overview') {
                this.scrollToTop = {target: this.listMenuItems[i].anchorId, offset: -80};
                // if (!this.isIeMenu) {
                this._scrollToService.scrollTo(this.scrollToTop);
                // }
                this.leftMenuService.setDevOverviewState(false);
                break;
              }
            }
          }
        }, 700);
      }
    });


    this.topPosition = this.waveTop.nativeElement.offsetTop;
    this.oversightPoint = this.topPosition;
    this.isActiveLeftMenu = false;
    this.isMenuItems = this.listMenuItems.length === 0 ? false : true;
    // })

    for (let i = 0; i < this.listMenuItems.length; i++) {
      if (this.listMenuItems[i].isActive) {
        this.activeIndexMenuItem = i;
        break;
      }
    }
    if (!this.cdRef['destroyed'])
      this.cdRef.detectChanges();

    this.subscription.add(this.leftMenuService.changeMenuItemEvent.subscribe((index) => {
      if (typeof index !== 'undefined') {
        this.changeCurrentPage(index);
      }
    }));

    this.subscription.add(this.leftMenuService.setMenuDataEvent.subscribe((data: HeaderMenuColumn[]) => {
      this.dataHeader = data;
    }));

    this.subscription.add(this.leftMenuService.setLeftNavigationEvent.subscribe((data: LeftMenuItem[]) => {
      //setTimeout(() => {
      this.href = this.router.url.split('#')[0];
      this.listMenuItems = data;
      if (!this.cdRef['destroyed'])
        this.cdRef.detectChanges();
      this.topPosition = this.waveTop.nativeElement.offsetTop;
      //});
    }));

    this.subscription.add(this.leftMenuService.changeDisableClickOnLeftMenuItem.subscribe((boo: boolean) => {
      this.disableClickOnLeftMenuItem = boo;
    }));

    this.subscription.add(this.leftMenuService.setHiddenDotEvent.subscribe((boo: boolean) => {
      setTimeout(() => {
        this.isHiddenDot = boo;
      });

      if (boo) {
        setTimeout(() => {
          this.isHiddenDot = false;
          if (this.listMenuItems[this.activeIndexMenuItem]) {
            this.listMenuItems[this.activeIndexMenuItem].isActive = false;
            if (!this.cdRef['destroyed'])
              this.cdRef.detectChanges();
          }
        });
      } else {
        setTimeout(() => {
          if (this.listMenuItems[this.activeIndexMenuItem]) {
            this.listMenuItems[this.activeIndexMenuItem].isActive = true;
            if (!this.cdRef['destroyed'])
              this.cdRef.detectChanges();
          }
        });
      }
    }));

    // first screens height check
    this.screenHeightCheck();
  }





  ngAfterViewInit() {
    // this.cdRef.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.mainMenu.nativeElement.contains(event.target)) {
      if (!this.hamburgerButton.nativeElement.contains(event.target)) {
        this.isActiveLeftMenu = false;
      } else {
        this.isActiveLeftMenu = !this.isActiveLeftMenu;
      }
    }
  }

  public handlerEventOnClick(_index) {
    if (!this.disableClickOnLeftMenuItem) {
      this.leftMenuService.changeMenuItemEvent.next(_index);
    } else {
      if (this.listMenuItems[_index]) {
        this.scrollToTop = {target: this.listMenuItems[_index].anchorId, offset: -80};
        if (!this.isIeMenu) {
          this._scrollToService.scrollTo(this.scrollToTop);
        }
      }
    }
  }


  public eventHoverInMenuItem(index) {
    this.temporaryValueForDot = this.isHiddenDot;
    this.isHiddenDot = false;
    this.topPosition = this.oversightPoint + this.waveMoveConst * (index);
  }

  public eventHoverOutMenuItem() {
    this.isHiddenDot = this.temporaryValueForDot;
    this.topPosition = this.oversightPoint + this.waveMoveConst * (this.activeIndexMenuItem);
  }

  public moveDotByIndex(index: number) {
    this.topPosition = this.oversightPoint + this.waveMoveConst * (index);
  }

  public toggleLeftMenu() {
    this.isActiveLeftMenu = !this.isActiveLeftMenu;
  }

  public openDestination() {
    // this.headerService.openDestinationMenu();
    this.router.navigateByUrl('/map').then();
    this.headerService.prevUrl = this.router.url;
  }

  private changeCurrentPage(_index: number = 0) {
    if (_index < 0) {
      _index = 0;
    }
    this.leftMenuService.setActiveMenuItem(_index);
    this.listMenuItems = this.leftMenuService.getMenuItems();
    this.moveDotByIndex(_index);
    this.activeIndexMenuItem = _index;
    const _header = this.headerService.getStateHeader();
    if (this.listMenuItems.length > 0) {
      _header.setFalseAllClasses();

      if (!this.listMenuItems[_index].whideWideHeader) {
        if (this.listMenuItems.length > 0 && this.listMenuItems[_index].transparentBackground) {
          _header.isClassMain = true;
        } else {
          _header.isClassInner = true;
        }
      } else {
        if (this.listMenuItems.length > 0 && this.listMenuItems[_index].whideWideHeader) {
          _header.isClassWhiteWide = true;
        }
      }
      this.headerService.setHeader(_header);
    }
    if (!this.cdRef['destroyed'])
      this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.leftMenuService.clearMenu();

    this.cdRef.detach();

    this.subscription.unsubscribe();
  }

  clickIeTagA() {
    if (this.isBrowser) {
      setTimeout(() => {
        if (window.pageYOffset - 80 > 0)
          window.scrollTo(0, window.pageYOffset - 80);
      });
    }
  }


  // set wavescroll is window height > 500px and < 720px
  screenHeightCheck() {
    if (this.isBrowser) {
      if (window.innerHeight > 540 && window.innerHeight < 710) {
        this.waveMoveConst = 30;
      } else {
        this.waveMoveConst = 40;
      }
    }
  }

  @HostListener('window:resize', [])
  onResize(e) {
    this.screenHeightCheck();
  }

  ngOnChanges() {
    // this.leftMenuService.setMenuItems(this.listMenuItems);
  }
}
