import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { StorageService } from '../services/storage.service';
import { UserService } from '../services/user.service';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {

    private readonly TIMEOUT_MINUTES = 120;
    private readonly LAST_REQUEST_KEY = 'lastRequestTime';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private localStorageService: StorageService,
        private userService: UserService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.userService.user) {
            // Check if we're running in a browser environment
            if (isPlatformBrowser(this.platformId)) {
                const now = Date.now();
                const lastRequestTime = Number(this.localStorageService.get(this.LAST_REQUEST_KEY, false));
                // If there was a previous request and it was more than TIMEOUT_MINUTES ago, log out the user
                if (lastRequestTime && (now - lastRequestTime) > this.TIMEOUT_MINUTES * 60 * 1000) {
                    this.userService.logout();
                } else {
                    // Otherwise, update the last request time
                    this.localStorageService.set(this.LAST_REQUEST_KEY, now.toString(), false);
                }
            }
        }
        // Proceed with the request
        return next.handle(req);
    }
}