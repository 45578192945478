import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import { ResourceUrlService } from '../../../../services/resource-url.service';

@Component({
  selector: 'app-left-image-text',
  templateUrl: './left-image-text.component.html',
  styleUrls: ['./left-image-text.component.scss']
})
export class LeftImageTextComponent implements OnInit {
  @Input() data: any;
  @ViewChild('image') image: ElementRef;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    if (this.image.nativeElement.getBoundingClientRect().top < window.innerHeight) {
      this.onScroll();
    }
  }

  makeLinkDownloadable() {
    if(this.data && this.data.Link) {
      if(this.data.Link.Url.indexOf("media") != -1) {
        return ResourceUrlService.url + this.data.Link.Url;
      }
      if(this.data.Link.Url.indexOf("http") != -1) {
        return this.data.Link.Url;
      }
      return this.data.Link.Url;
    }
    return "";
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const offset = window.pageYOffset;
    const elOffset = window.pageYOffset + this.image.nativeElement.getBoundingClientRect().top;
    if (offset + (window.innerHeight - (this.elementRef.nativeElement.offsetHeight / 2.5)) >= elOffset) {
      const image = this.elementRef.nativeElement.querySelector('.image-text-module');
      image.classList.add('animate');
    }
  }
}
