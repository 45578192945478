import { DestinationSectionMenu } from "./data-header.model";

export class HeaderData{
  MegaMenu: HeaderMenu;
  DestinationMenu: DestinationSectionMenu[];
}

export class HeaderMenu {
    Id: number;
    MenuColumns: HeaderMenuColumn[];
    Name: string;
}

export class HeaderMenuColumn { 
  Headline: string;
  Icon: string;
  Links: HeaderMenuLink[];
}

export class HeaderMenuLink {
  Name: string;
  Target: string;
  Type: string;
  Url: string;
}


