import {BreadCrumbsContacts} from './contacts.model';

export class AboutPageModel {
  BreadCrumbs: BreadCrumbsContacts[];
  WatchTheVideo: WatchTheVideoAbout;
  WhyCruiseBaltic: WhyCruiseBalticAbout;

  constructor() {
    this.BreadCrumbs = [];
    this.WatchTheVideo = new WatchTheVideoAbout();
    this.WhyCruiseBaltic = new WhyCruiseBalticAbout();
  }
}

export class WatchTheVideoAbout {
  Anchor: string;
  Header: string;
  Link: string;
  LinkText: string;

  constructor() {
    this.Anchor = '';
    this.Header = '';
    this.Link = '';
    this.LinkText = '';
  }
}

export class WhyCruiseBalticAbout {
  Modules: any[];
  Anchor: string;
  constructor() {
    this.Anchor = '';
    this.Modules = [];
  }
}
