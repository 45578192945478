import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {LeftMenuService} from '../left-menu/left-menu.service';
import {Subscription} from "rxjs/Subscription";

@Component({
  selector: 'app-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss']
})
export class HeaderLogoComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('logoHolder') logoHolder: ElementRef;

  public lottieConfig: Object;
  private anim: any;
  private animationSpeed = 1;
  private activeDot: number = 0;
  private timeout1 = null;
  private timeout2 = null;

  private subscription: Subscription = new Subscription();

  constructor(private leftMenuService: LeftMenuService,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.lottieConfig = {
      path: './assets/svg-json/header-logo.json',
      autoplay: true,
      loop: true
    };

    this.subscription.add(this.leftMenuService.changeMenuItemEvent.subscribe((index) => {
      if (index !== -1) {
        this.annimateLogo();
      }
    }));

  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.annimateLogo();
  }

  stop() {
    this.anim.stop();
  }

  play() {
    this.anim.play();
  }

  pause() {
    this.anim.pause();
  }

  setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
  }

  handleAnimation(anim: any) {
    this.anim = anim;
    // this.play();
    // setTimeout(() => {
    //   this.pause();
    // }, 1350);
  }

  annimateLogo() {
    let temp = Math.floor(Math.random() * 7) + 1;
    while (temp === this.activeDot) {
      temp = Math.floor(Math.random() * 7) + 1;
    }
    this.renderer.addClass(this.logoHolder.nativeElement, 'dot-stop');
    this.renderer.removeClass(this.logoHolder.nativeElement, 'dot-animate');
    if (this.timeout1) {
      clearTimeout(this.timeout1);
      this.timeout1 = null;
    }
    this.timeout1 = setTimeout(() => {
      for (let i = 1; i < 8; i++) {
        this.renderer.removeClass(this.logoHolder.nativeElement, 'dot' + i);
      }
    }, 300);


    if (this.timeout2) {
      clearTimeout(this.timeout2);
      this.timeout2 = null;
    }
    this.timeout2 = setTimeout(() => {
        this.activeDot = temp;
        this.renderer.removeClass(this.logoHolder.nativeElement, 'dot-stop');
      this.renderer.removeClass(this.logoHolder.nativeElement, 'dot-hide');
        this.renderer.addClass(this.logoHolder.nativeElement, 'dot-animate');
        this.renderer.addClass(this.logoHolder.nativeElement, 'dot' + this.activeDot);
      }, 600);

  }

}
