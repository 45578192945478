import {Component, Input, OnInit} from '@angular/core';
import {UrlHelper} from "../../../../helpers/url.helper";

@Component({
  selector: 'app-teasers',
  templateUrl: './teasers.component.html',
  styleUrls: ['./teasers.component.scss']
})
export class TeasersComponent implements OnInit {
  @Input() data: any;
  @Input() isLastModule: boolean;

  constructor() { }

  ngOnInit() {
  }

  isItExternal(link: string): boolean {
    if (link) {
      return UrlHelper.isItExternal(link);
    } else {
      return true;
    }
  }

  correctLink(link: string) {
    if(link.indexOf("http") != -1) {
      return link;
    }
    return link;
  }
}
