import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {SvgIconsComponent} from './svg-icons/svg-icons.component';
import {SvgIconsService} from './svg-icons/svg-icons.service';
import {ScrollTopComponent} from './scroll-top/scroll-top.component';
import {ScrollDownComponent} from './scroll-down/scroll-down.component';
import {LeftMenuComponent} from './left-menu/left-menu.component';
import {ImageHostPipe} from '../pipes/image-host.pipe';
import {CountryIsoPipe} from '../pipes/country-iso.pipe';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {ScrollListenerDirective} from '../directives/scroll-listener/scroll-listener.directive';
import {PulseCircleComponent} from './pulse-circle/pulse-circle.component';
import {FormateDayMonthPipe} from '../pipes/formate-day-month.pipe';
import {FormateYearPipe} from '../pipes/formate-year.pipe';
import {PDatePipe} from '../pipes/p-calendar-date.pipe';
import {IdGeneratorPipe} from '../pipes/id-generator.pipe';
import {HeaderLogoComponent} from './header-logo/header-logo.component';
import {TopbanerComponent} from '../modularpages/modularpages/components/topbaner/topbaner.component';
import {TeasersComponent} from '../modularpages/modularpages/components/teasers/teasers.component';
import {LeftImageTextComponent} from '../modularpages/modularpages/components/left-image-text/left-image-text.component';
import {CenteredTextComponent} from '../modularpages/modularpages/components/centered-text/centered-text.component';
import {LogoTextComponent} from '../modularpages/modularpages/components/logo-text/logo-text.component';
import {DownloadModuleComponent} from '../modularpages/modularpages/components/download-module/download-module.component';
import {ContactModuleComponent} from '../modularpages/modularpages/components/contact-module/contact-module.component';
import {RightImageTextComponent} from '../modularpages/modularpages/components/right-image-text/right-image-text.component';
import {ModalDialogModule} from 'ngx-modal-dialog';
import {ModalDialogComponent} from './modal-dialog/modal-dialog.component';
import {PaginationNavComponent} from './pagination-nav/pagination-nav.component';
import {AlertDialogComponent} from './alert-dialog/alert-dialog.component';
import {FooterGlobalComponent} from './footer/footer.component';
import {PopupBarComponent} from './popup-bar/popup-bar.component';
import {ModulesButtonComponent} from './modules-button/modules-button.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {RemoveExtensionPipe} from '../pipes/remove-extension.pipe';
import {TextModuleComponent} from '../modularpages/modularpages/components/text-module/text-module.component';
import {OneTeaserModuleComponent} from '../modularpages/modularpages/components/one-teaser-module/one-teaser-module.component';
import {CountCharacterComponent} from './count-character/count-character.component';
import { GtagDirective } from '../directives/gtag/gtag.directive';
import { SeasonLegendComponent } from './season-legend/season-legend.component';
import {AngularSvgIconModule} from 'angular-svg-icon';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ScrollToModule.forRoot(),
        ModalDialogModule.forRoot(),
        AngularSvgIconModule
    ],
  exports: [
    SvgIconsComponent,
    ScrollTopComponent,
    ScrollDownComponent,
    PulseCircleComponent,
    LeftMenuComponent,
    ImageHostPipe,
    CountryIsoPipe,
    ScrollListenerDirective,
    FormateDayMonthPipe,
    FormateYearPipe,
    PDatePipe,
    IdGeneratorPipe,
    HeaderLogoComponent,
    ModalDialogComponent,
    TopbanerComponent,
    TeasersComponent,
    LeftImageTextComponent,
    CenteredTextComponent,
    TextModuleComponent,
    OneTeaserModuleComponent,
    LogoTextComponent,
    DownloadModuleComponent,
    ContactModuleComponent,
    RightImageTextComponent,
    PaginationNavComponent,
    AlertDialogComponent,
    FooterGlobalComponent,
    PopupBarComponent,
    // modules
    ScrollToModule,
    SwiperModule,
    RemoveExtensionPipe,
    CountCharacterComponent,
    GtagDirective,
    SeasonLegendComponent
  ],
  declarations: [
    SvgIconsComponent,
    ScrollTopComponent,
    ScrollDownComponent,
    LeftMenuComponent,
    ImageHostPipe,
    CountryIsoPipe,
    ScrollListenerDirective,
    PulseCircleComponent,
    FormateDayMonthPipe,
    FormateYearPipe,
    PDatePipe,
    IdGeneratorPipe,
    HeaderLogoComponent,
    TopbanerComponent,
    TeasersComponent,
    LeftImageTextComponent,
    CenteredTextComponent,
    TextModuleComponent,
    OneTeaserModuleComponent,
    LogoTextComponent,
    DownloadModuleComponent,
    ContactModuleComponent,
    RightImageTextComponent,
    ModalDialogComponent,
    PaginationNavComponent,
    AlertDialogComponent,
    FooterGlobalComponent,
    PopupBarComponent,
    ModulesButtonComponent,
    RemoveExtensionPipe,
    CountCharacterComponent,
    GtagDirective,
    SeasonLegendComponent
    // modules
  ],
  providers: [
    SvgIconsService
  ],
  entryComponents: [
    ModalDialogComponent,
    AlertDialogComponent
  ]
})
export class SharedModule {
}
