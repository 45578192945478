import {Injectable} from '@angular/core';
import {LeftMenuItem} from '../../classes/left-menu-item';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {SectionMegaMenu} from '../../classes/data-header.model';
import {Subject} from 'rxjs/Subject';
import { HeaderMenuColumn } from '../../classes/data-header-new.model';

@Injectable()
export class LeftMenuService {
  public changeMenuItemEvent = new BehaviorSubject<number>(-1);
  public changeDisableClickOnLeftMenuItem = new BehaviorSubject<boolean>(true);
  public setMenuDataEvent = new BehaviorSubject<HeaderMenuColumn[]>([]);
  public setHiddenDotEvent = new Subject<boolean>();
  public checkPositionEvent = new Subject<boolean>();
  public setLeftNavigationEvent = new BehaviorSubject<LeftMenuItem[]>([]);
  private sectionsHeigth: number[];
  private menuItems: LeftMenuItem[];
  private goToDevOverview: boolean = false;
  public goToDevOverviewState = new BehaviorSubject<boolean>(this.goToDevOverview);

  constructor() {
    this.menuItems = [];
  }

  public getMenuItems() {
    return this.menuItems;
  }

  public setMenuItems(_menuItems: LeftMenuItem[]) {
    this.menuItems = _menuItems;
    this.setLeftNavigationEvent.next(_menuItems);
  }

  public clearMenu() {
    this.changeMenuItemEvent.next(0);
    this.menuItems = [];
    this.setLeftNavigationEvent.next(this.menuItems);
  }

  public setActiveMenuItem(index) {
    if (this.menuItems && this.menuItems.length > 0) {
      for (let i = 0; i < this.menuItems.length; i++) {
        if (index !== i) {
          this.menuItems[i].isActive = false;
        } else {
          this.menuItems[i].isActive = true;
        }
      }
    }
  }

  public setNextActiveMenuItem() {
    if (this.menuItems && this.menuItems.length > 0) {
      for (let i = 0; i < this.menuItems.length; i++) {
        if (this.menuItems[i].isActive && i !== this.menuItems.length) {
          this.setActiveMenuItem(i + 1);
          this.changeMenuItemEvent.next(i + 1);
          break;
        }
      }
    }
  }

  public getIndexMenuItemByName(name): number {
    for (let i = 0; i < this.menuItems.length; i++) {
      if (this.menuItems[i].title === name) {
        return i;
      }
    }
  }

  public getActiveIndex(): number {
    for (let i = 0; i < this.menuItems.length; i++) {
      if (this.menuItems[i].isActive === true) {
        return i;
      }
    }
  }

  public getActiveMenuItem(): LeftMenuItem {
    for (let i = 0; i < this.menuItems.length; i++) {
      if (this.menuItems[i].isActive === true) {
        return this.menuItems[i];
      }
    }
  }

  public setSectionsHeigth(_masSections: number[]) {
    this.sectionsHeigth = _masSections;
  }

  public removeListMenuItemById(_id: string) {
    const tempArray: LeftMenuItem[] = [];
    for (let i = 0; i < this.menuItems.length; i++) {
      if (this.menuItems[i].anchorId !== _id) {
        tempArray.push(this.menuItems[i]);
      }
    }
    this.menuItems = tempArray;
    this.setMenuItems(this.menuItems);
  }

  public setDevOverviewState(state: boolean) {
    this.goToDevOverviewState.next(state);
  }
}
