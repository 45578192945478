import {
  Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2, ViewChild
} from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { Header } from '../../classes/header';
import { ConfigService } from '../../services/config.service';
import { ResourceUrlService } from '../../services/resource-url.service';
import { HeaderDataModel, SectionMegaMenu } from '../../classes/data-header.model';
import { LeftMenuService } from '../left-menu/left-menu.service';
import { LocationMap, LocationsItems } from '../../classes/map-locations';
import { DestinationPreviewItem } from '../../classes/destination-preview-item.modal';
import { DOCUMENT } from '@angular/common';
import { ScrollToTopService } from '../../services/scroll-to-top.service';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { HeaderData, HeaderMenu, HeaderMenuColumn } from '../../classes/data-header-new.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('headerHolder') headerHolder: ElementRef;

  isOpenMegaMenu = false;
  public dataHeader: HeaderMenuColumn[];

  private currentActiveDot: LocationMap;
  private isHidden: LocationMap;
  public header: Header = null;
  public data: HeaderMenu = new HeaderMenu();

  public LocationsItems = LocationsItems;
  private subscription: Subscription = new Subscription();

  private prevExpand = null;

  showLoginButton: boolean = true;

  constructor(public headerService: HeaderService,
    private configService: ConfigService,
    private leftMenuService: LeftMenuService,
    public userService: UserService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private scrollToTop: ScrollToTopService,
    private router: Router) {
  }

  ngOnInit() {
    this.headerService.headerHeigth = this.headerHolder.nativeElement.offsetHeight;
    this.header = this.headerService.getStateHeader();

    this.subscription.add(this.headerService.headerEvent.subscribe((value) => {
      this.header = value;
    }));

    this.subscription.add(this.leftMenuService.setMenuDataEvent.subscribe((data: HeaderMenuColumn[]) => {
      this.dataHeader = data;
    }));

    this.configService.getConfig(ResourceUrlService.getCommonData13)
      .then((data: HeaderData) => {
        this.data = data.MegaMenu;
        this.leftMenuService.setMenuDataEvent.next(data.MegaMenu.MenuColumns);
        //this.showDestination(data);
      });

      if (this.userService.user != null) {
        this.showLoginButton = false;
      }
      this.subscription.add(this.userService.userChange.subscribe(() => {
        if (this.userService.user != null) {
          this.showLoginButton = false;
        } else {
          this.showLoginButton = true;
        }
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public toggleMenu() {
    this.isOpenMegaMenu = !this.isOpenMegaMenu;
  }

  public closeMenu() {
    if (this.isOpenMegaMenu) {
      this.isOpenMegaMenu = false;
    }
  }

  public toggleRightMenu() {
    // this.openCloseMenu();
    if (this.headerService.prevUrl === '') {
      if (this.router.url === '/map') {
        this.router.navigateByUrl('/').then();
      } else {
        this.router.navigateByUrl('/map').then();
        this.headerService.prevUrl = this.router.url;
      }
    } else {
      this.router.navigateByUrl(this.headerService.prevUrl).then(() => {
        // this.openCloseMenu();
        this.headerService.prevUrl = '';
      });
    }
  }

  yourDestination() {
    if (this.userService.user != null) {
      // this.router.navigate(['/partner-intranet/' + this.userService.user.Id]).then();
      this.toggleMenu();
      this.router.navigate(['partner-intranet', this.userService.user.Destinations[0], 'welcome']);
    } else {
      this.toggleMenu();
      this.logout();
    }
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['/']).then();
    this.toggleMenu();

  }

  public openExpand(el: HTMLElement) {
    if (el.classList.contains('selected')) {
      this.renderer.removeClass(el, 'selected');
    } else {
      this.renderer.addClass(el, 'selected');
      if (el !== this.prevExpand && this.prevExpand !== null) {
        this.renderer.removeClass(this.prevExpand, 'selected');
      }
      this.prevExpand = el;
    }
  }

  // public showDestination(data) {
  //   for (let i = 0; i < this.data.DestinationMenu.length; i++) {
  //     for (let j = 0; j < this.data.DestinationMenu[i].Items.length; j++) {
  //       for (let k = 0; k < this.LocationsItems.length; k++) {
  //         if (this.LocationsItems[k].locationUrl.toLowerCase() === this.data.DestinationMenu[i].Items[j].Url.toLowerCase()) {
  //           this.LocationsItems[k].isHidden = true;
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }

  animateDot(item: DestinationPreviewItem) {
    for (let i = 0; i < this.LocationsItems.length; i++) {
      if (this.LocationsItems[i].locationUrl.toLowerCase() === item.Url.toLowerCase()) {
        this.currentActiveDot = this.LocationsItems[i];
        break;
      }
    }
    if (this.currentActiveDot) {
      this.currentActiveDot.isAnimate = true;
    }
  }

  stopAnimate() {
    this.currentActiveDot.isAnimate = false;
  }

  ScrolToTop() {
    this.scrollToTop.scrollToTop();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.headerService.headerSizeSubscription.next(this.headerHolder.nativeElement.offsetHeight);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (window.scrollY > 0 || document.documentElement.scrollTop > 0) {
      this.header.isClassInner = true;
    } else {
      this.header.isClassInner = false;
    }
  }

  private openCloseMenu() {
    this.header.isActiveRightMenu = !this.header.isActiveRightMenu;
    if (this.header.isActiveRightMenu || this.header.isDevOverview) {
      this.document.body.classList.add('hide-scroll');
    }
    else {
      this.document.body.classList.remove('hide-scroll');
    }
  }
}
