import {Component, Input, OnInit} from '@angular/core';
import {ResourceUrlService} from "../../../../services/resource-url.service";

@Component({
  selector: 'app-download-module',
  templateUrl: './download-module.component.html',
  styleUrls: ['./download-module.component.scss']
})
export class DownloadModuleComponent implements OnInit {
  host: string = ResourceUrlService.url;
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
