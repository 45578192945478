import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import {SvgIconsService} from './svg-icons.service';

@Component({
  selector: 'app-svg-icons',
  template: `<div #dataContainer class="{{name}}"></div>`,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./svg-icons.component.scss'],
})
export class SvgIconsComponent implements OnInit {

  @ViewChild('dataContainer') dataContainer: ElementRef;

  @Input() name: string;

  constructor(private svgIconsService: SvgIconsService) {
  }

  ngOnInit() {
    this.dataContainer.nativeElement.innerHTML = this.svgIconsService.getSvgBodyByName(this.name);
  }

}
