import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'extension' })

export class RemoveExtensionPipe implements PipeTransform {
  transform(item) {
    if (item.indexOf('.') == -1) return item;
    return item.substring(0, item.indexOf('.'));
  }
}
