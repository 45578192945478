import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {v} from '@angular/core/src/render3';

@Component({
  selector: 'app-count-character',
  templateUrl: './count-character.component.html',
  styleUrls: ['./count-character.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountCharacterComponent {

  private readonly longMaxCount = 1500;
  private readonly shortMaxCount = 50;
  currentCount = 0;
  maxCountChart = this.shortMaxCount;

  @Input() set isShortDescription(value) {
    if (!value) {
      this.maxCountChart = this.longMaxCount;
    }
  }

  @Input() set currentCountChart(value) {
    this.currentCount = value ? value.length : 0;
    this.cdRef.markForCheck();
  }

  constructor(private cdRef: ChangeDetectorRef) {
  }

  onInputChange(value) {
    const length = value ? value.length : 0;
    this.currentCount = length;
    if (length > this.maxCountChart) {
      this.currentCount = this.maxCountChart;
    }
    this.cdRef.markForCheck();
  }

  getMaxCountSize(): number {
    return this.maxCountChart;
  }
}
