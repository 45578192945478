import {Injectable} from "@angular/core";
import {StorageService} from "./storage.service";
import {HttpHeaders} from "@angular/common/http";
import {LoginResponseModel} from "../classes/login.model";
@Injectable()
export class HeadersService {

  private readonly LOCAL_STORAGE_KEY_TOKEN = 'user_access_token_cruisebaltic_application';
  private readonly LOCAL_STORAGE_KEY_USER = 'application_user_cruise-baltic';

  private _token: string = null;
  public get token(): string {
    if (this._token == null)
      this._token = this.storageService.get(this.LOCAL_STORAGE_KEY_TOKEN, false);
    return this._token;
  }

  public set token(t: string) {
    this._token = t;
    if (t == null || t == '') {
      this.storageService.remove(this.LOCAL_STORAGE_KEY_TOKEN);
    } else {
      this.storageService.set(this.LOCAL_STORAGE_KEY_TOKEN, t, false);
    }
  }




  public get user(): LoginResponseModel {
    return this.storageService.get(this.LOCAL_STORAGE_KEY_USER, true);
  }

  constructor(public storageService: StorageService) {
    // StorageService.remove(this.LOCAL_STORAGE_KEY_TOKEN);
  }

  public getHeaders(): HttpHeaders {
    let customHeaders = new HttpHeaders();
    if (this.token != null && this.user != null) {
      customHeaders = new HttpHeaders({'Authorization': "Bearer " + this.token, "UserId" : this.user.Id.toString()});
      // customHeaders = new HttpHeaders({'UserId': this.user.Id.toString()});
    }

    return customHeaders;
  }
}
