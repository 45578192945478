import {Injectable} from '@angular/core';
import {Header} from '../classes/header';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ResourceUrlService} from './resource-url.service';

@Injectable()
export class HeaderService {

  public headerSizeSubscription = new BehaviorSubject<number>(110);

  public prevUrl = '';

  private _headerHeigth: number = 110;

  get headerHeigth(): number {
    return this._headerHeigth;
  }

  set headerHeigth(headerHeigth: number) {
    this._headerHeigth = headerHeigth;
  }

  public headerEvent = new Subject<Header>();
  private headerModel: Header = null;

  constructor() {
    this.headerModel = new Header();
    this.headerModel.isClassMain = false;
  }

  public addHeaderClass(_class: string) {

  }

  public setWhiteWideHeader(boo: boolean) {
    this.headerModel.setFalseAllClasses();
    this.headerModel.isClassWhiteWide = boo;
    this.headerEvent.next(this.headerModel);
  }

  public getStateHeader() {
    return this.headerModel;
  }

  public setHeader(_header: Header) {
    this.headerModel = _header;
    this.headerEvent.next(_header);
  }

  public setHeaderBackground(_boo: boolean) {
    this.headerModel.isClassMain = _boo;
    this.headerEvent.next(this.headerModel);
  }

  public setButtonDownloadActive(_boo: boolean, _isBlocked: boolean = false, id: string) {
    this.headerModel.isHiddenButtonDownload = _boo;
    this.headerModel.isBlockedButton = _isBlocked;
    this.headerModel.linkToPdf = ResourceUrlService.getDestinationPagePdf + id;
  }

  public setHiddenDestinationMenu(_isDestinationButtonHidden: boolean = false) {
    this.headerModel.destinationInfoBtn = _isDestinationButtonHidden;
  }

  public clearHeader() {
    this.headerModel = new Header();
    this.headerEvent.next(this.headerModel);
  }

  public openDestinationMenu() {
    this.headerModel.isActiveRightMenu = true;
    this.headerEvent.next(this.headerModel);
  }
}
