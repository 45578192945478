export class RandomService{
  public static randomize(from: number, to: number): number{
    let f = from;
    let t = to;
    if (to < from) {
      t = from;
      f = to;
    }
    return Math.floor(Math.random() * t) + f;
  }
}
