import {Pipe, PipeTransform} from '@angular/core';
import {month_names_short} from '../classes/month-name';
import {HelpersService} from '../services/helpers.service';

@Pipe({
  name: 'formateDayMonth'
})
export class FormateDayMonthPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return new Date((value)).getDate() + ' ' + month_names_short[new Date(value).getMonth()];
  }

}
