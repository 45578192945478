import {Component, OnDestroy, OnInit} from '@angular/core';
import {ResourceUrlService} from '../../services/resource-url.service';
import {PopupWindowData} from '../../classes/popup-bar';
import {ConfigService} from '../../services/config.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-popup-bar',
  templateUrl: './popup-bar.component.html',
  styleUrls: ['./popup-bar.component.scss']
})
export class PopupBarComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  setVisiblePopup = false;
  public data: PopupWindowData = new PopupWindowData();

  constructor(private configService: ConfigService) {
  }

  ngOnInit() {
    this.configService.getConfig(ResourceUrlService.getPopupWindowData)
      .then((dataHeader: PopupWindowData) => {
        this.data = dataHeader;
        // console.log(dataHeader);
      });

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  SetVisible() {
    this.setVisiblePopup = !this.setVisiblePopup;
  }
}
