import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HeaderService} from '../../services/header.service';
import {Subscription} from 'rxjs/Subscription';
import {AboutPageModel} from '../../classes/about-page.model';

@Component({
  selector: 'app-scroll-down',
  templateUrl: './scroll-down.component.html',
  styleUrls: ['./scroll-down.component.scss']
})
export class ScrollDownComponent implements OnInit, OnDestroy {

  public data: AboutPageModel = new AboutPageModel();

  public heigthHeader: number = 110;
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed = 1;
  private subscribtion: Subscription = new Subscription();

  @Input() menuTitleNext: string;
  @Input() dataHref: string;

  constructor(private headerService: HeaderService) {
  }

  ngOnInit() {
    // this.lottieConfig = {
    //   path: './assets/svg-json/scroll-down.json',
    //   autoplay: true,
    //   loop: true
    // };

    this.heigthHeader = this.headerService.headerHeigth - 30;

    this.subscribtion.add(this.headerService.headerSizeSubscription.subscribe((size) => {
      this.heigthHeader = size -30;
    }));

  }

  ngOnDestroy() {
    this.subscribtion.unsubscribe();
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  // stop() {
  //   this.anim.stop();
  // }

  play() {
    this.anim.play();
  }

  // pause() {
  //   this.anim.pause();
  // }

  setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
  }

  // setNextPage() {
  //   this.leftMenuService.setNextActiveMenuItem();
  // }

}
