//import {Storage} from "@ionic/storage";

import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class StorageService {
  lc = null;

  //private static storage : Storage = new Storage;
  private readonly APP_STORAGE_KEY = "dkovdalcruisebaltic";

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.lc = localStorage;
    } else {
      this.lc = {
        clear: () => {
          return null;
        },
        getItem: (key: string) => {
          return null;
        },
        key: (index: number) => {
          return null;
        },
        removeItem: (key: string) => {
          return null;
        },
        setItem: (key: string, data: string) => {
          return null;
        },
      };
    }

  }

  set(key: string , value: any, isObject : boolean) {
    let val = "";
    if(isObject){
      val = JSON.stringify(value);
    } else {
      val = value;
    }
    try{
      this.lc.setItem(key + this.APP_STORAGE_KEY, val);//this.storage.set(key, value);
    }
    catch(e){
      //alert("storage error: " + val.length); //todo comment it out before release
    }
  }
  get(key: string, isObject: boolean): any {
    let val : any = null;
    if(isObject){
      let storagedata: string = this.lc.getItem(key + this.APP_STORAGE_KEY);
      if (storagedata != null) val = JSON.parse(storagedata);
    } else {
      val = this.lc.getItem(key + this.APP_STORAGE_KEY);
    }
    return val;
  }
  remove(key:string){
    this.lc.removeItem(key + this.APP_STORAGE_KEY);
  }
}

