import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pdate'
})
export class PDatePipe implements PipeTransform {

  transform(value: string): Date {
    return new Date(value);
  }

}
