import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class FooterService {

  public activeFooterInScroll: boolean = false;
  public ScrollPositionSubscription = new BehaviorSubject<boolean>(false);
  public isHiddenFooterSubscription = new Subject<boolean>();

  constructor() { }

}
