import {
  Component,
  ViewEncapsulation,
  OnInit,
  Renderer2,
  PLATFORM_ID,
  Inject,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {LeftMenuService} from './components/left-menu/left-menu.service';
import {HeaderService} from './services/header.service';
import {LoaderService} from './services/loader.service';
import {FooterService} from './services/footer.service';
import {ScrollToTopService} from './services/scroll-to-top.service';
import {isPlatformBrowser, DOCUMENT} from '@angular/common';
import {SeoHelperService} from './services/seo-helper-service.service';
import {Subscription} from 'rxjs/Subscription';
import {UserService} from './services/user.service';
import {HttpService} from './services/http.service';
import {delay, tap} from 'rxjs/operators';

declare let ga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'app';
  previousUrl: string;
  isBrowser;
  isLoaderShown = false;

  subscription: Subscription = new Subscription();

  constructor(private router: Router,
              private leftMenuService: LeftMenuService,
              private headerService: HeaderService,
              public loaderService: LoaderService,
              private footerService: FooterService,
              public userService: UserService,
              public httpService: HttpService,
              private scrollToTop: ScrollToTopService,
              @Inject(PLATFORM_ID) private platformId,
              private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document,
              private seoHelperService: SeoHelperService) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    //this.OnCloseLogout();
    this.addCookiebot();
    this.initRouteReplace();

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.initLoader();
  }

//   OnCloseLogout() {
//     let context = this;
//     window.addEventListener("beforeunload", function (e) {
//       context.userService.logout();
//       context.httpService.get(ResourceUrlService.url + ResourceUrlService.getLogOut).toPromise().then(data => {
//       });
//     });
// }

  private clearStateData() {
    if (this.router.url.indexOf(this.previousUrl) === -1 || this.previousUrl === '') {
      this.leftMenuService.clearMenu();
      this.headerService.clearHeader();
      this.footerService.isHiddenFooterSubscription.next(true);
      this.scrollToTop.scrollToTop();

    }
  }

  private addCookiebot() {
    if (!this.isBrowser) {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://consent.cookiebot.com/uc.js';
    this._renderer2.setAttribute(script, 'data-cbid', '6a587fee-885c-44c2-8c12-4ab7c0d6ee4e');
    this._renderer2.setAttribute(script, 'data-blockingmode', 'auto');
    this._renderer2.appendChild(this._document.body, script);
  }

  private initRouteReplace() {
    this.subscription.add(this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe((event: any) => {
        if (this.router.url.indexOf('#') === -1) {
          if (this.router.url !== '/' && this.router.url !== '') {
            this.clearStateData();
            this.seoHelperService.resetMetaTags();
          } else {
            if (this.isBrowser) {
              window.scrollTo(0, 0);
            }
          }
          this.previousUrl = this.router.url.replace(/^\/([^\/]*).*$/, '$1');
        }

        //PAY ATTENTION it should be uncommented before go live
        // ga('set', 'page', event.urlAfterRedirects);
        // ga('send', 'pageview');
      }));
    this.previousUrl = this.router.url.replace(/^\/([^\/]*).*$/, '$1');
  }

  private initLoader() {
    this.subscription.add(this.loaderService.getLoadingStatus().pipe(
      delay(400),
      tap(status => {
        this.isLoaderShown = status;
      })
    ).subscribe());
  }

}
