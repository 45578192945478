export class Header {
  public isClassWhiteWide: boolean;
  public isClassMain: boolean;
  public isClassInner: boolean;
  public destinationInfoBtn: boolean;
  public isBlockedButton: boolean;
  public isActiveRightMenu: boolean;
  public isHiddenButtonDownload: boolean;
  public textButtonDownload: string;
  public linkForDownloadFile: string;
  public linkToPdf: string;
  public isDevOverview: boolean;

  constructor() {
    this.destinationInfoBtn = false;
    this.isClassWhiteWide = false;
    this.isClassMain = true;
    this.isClassInner = false;
    this.isActiveRightMenu = false;
    this.isBlockedButton = false;
    this.isHiddenButtonDownload = true;
    this.textButtonDownload = 'DOWNLOAD FACT SHEET';
    this.linkForDownloadFile = '#';
    this.linkToPdf = '';
    this.isDevOverview = false;
  }

  setFalseAllClasses() {
    this.isClassWhiteWide = false;
    this.isClassMain = false;
    this.isClassInner = false;
  }
}
