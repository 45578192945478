import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT, isPlatformBrowser, Location} from '@angular/common';

@Injectable()
export class SeoHelperService {

  isBrowser;
  private activeTitle: string = '';

  constructor(private meta: Meta,
              private titleService: Title,
              @Inject(DOCUMENT) private doc,
              private loc: Location,
              @Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public setSeoProps(meta, title, description) {
    if (meta && meta.Title) {
      this.activeTitle = meta.Title;
      this.concatinateTitle(meta.Title);
    } else {
      this.activeTitle = title;
      this.concatinateTitle(title);
    }

    if (meta && meta.Description) {
      this.concatinateDescription(meta.Description);
    } else {
      this.concatinateDescription(description);
    }
  }

  public addCanonicalLink(url) {
    if (this.isBrowser) {
      const angularRoute = this.loc.path();
      let domain = window.location.href.substring(0, window.location.href.indexOf(angularRoute)) + '/' + url;
      let link: HTMLLinkElement = this.doc.createElement('link');

      const links = this.doc.getElementsByTagName('link');
      for (let i = 0; i < links.length; i++) {
        if (links[i].href === domain && links[i].rel === 'canonical') {
          return;
        }
      }

      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', domain);
    }
  }

  public concatinateTitle(_concat: string) {
    _concat = (!_concat) ? ' ' : ` - ${_concat.trim()}`;
    const _title = this.titleService.getTitle().indexOf('CruiseBaltic') === -1 ? 'CruiseBaltic ' + this.titleService.getTitle() : this.titleService.getTitle();
    if (_title.indexOf(_concat) === -1) {
      this.titleService.setTitle(`${_title} ${_concat}`);
    }
  }

  public concatinateDescription(_concat: string) {
    _concat = (!_concat) ? ' ' : _concat.trim();
    const _description = this.meta.getTag('name=description');
    if (!_description) {
      this.meta.addTag({name: 'description', content: `${_concat}`});
    } else {
      this.meta.updateTag({
        name: 'description',
        content: `${_description.content}. ${_concat} `
      });
    }
  }

  public removeSubTitle(_subTitle: string) {
    _subTitle = !_subTitle ? null : _subTitle.trim();
    let title = this.titleService.getTitle();
    if (_subTitle !== '' && title.indexOf(_subTitle) > -1) {
      title = title.substr(0, title.indexOf(_subTitle) - 2);
      this.titleService.setTitle(title);
    }
  }

  public clearCanonicalLinks() {
    const links = this.doc.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
      if (links[i].rel === 'canonical') {
        links[i].remove();
      }
    }
  }

  public clearTitle() {
    this.titleService.setTitle('CruiseBaltic');
  }

  public resetMetaTags() {
    this.meta.removeTag('name=description');
    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=robots');
  }

  public setNoIndexNoFollow(_meta: any) {
    if (_meta && _meta.NoIndex) {
      this.meta.updateTag({name: 'robots', content: 'noindex, nofollow'});
    }
  }
  public resetMetaNoIndexNoFollow() {
    this.meta.removeTag('name=robots');
  }

}
