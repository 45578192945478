import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formateYear'
})
export class FormateYearPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return new Date(value).getFullYear();
  }

}
