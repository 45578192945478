import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {LocationMap, LocationsItemsLeftDot} from '../../classes/map-locations';
import {DestinationPreviewItem} from '../../classes/destination-preview-item.modal';
import {AllDestinations, FrontpageModals} from '../../classes/frontpage.modals';
import {ConfigService} from '../../services/config.service';
import {ResourceUrlService} from '../../services/resource-url.service';
import {FooterService} from '../../services/footer.service';
import {Subscription} from 'rxjs/Subscription';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-footer-global',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterGlobalComponent implements OnInit, OnDestroy, AfterViewInit {
  private currentActiveDot: LocationMap;
  data: AllDestinations;
  public LocationsItems = LocationsItemsLeftDot;
  public isHiddenFooter: boolean = false;
  private isHiddenFooterSubscription: Subscription = new Subscription();
  private notChangedFooter = true;
  scrollToTop: ScrollToConfigOptions = {
    target: 'header'
  };

  constructor(private configService: ConfigService,
              private footerService: FooterService,
              private _scrollToService: ScrollToService) {
  }

  ngOnInit() {
    this.data = new AllDestinations();

    this.configService.getConfig(ResourceUrlService.getFooterData13)
      .then((data: AllDestinations) => {
        this.data = data;
      });

    this.isHiddenFooterSubscription = this.footerService.isHiddenFooterSubscription.subscribe((boo: boolean) => {
      this.isHiddenFooter = boo;
      this.notChangedFooter = false;
    });
  }

  ngOnDestroy() {
    this.isHiddenFooterSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.notChangedFooter) {
        this.isHiddenFooter = true;
      }
    }, 1000);
  }

  animateDot(item: DestinationPreviewItem) {
    for (let i = 0; i < this.LocationsItems.length; i++) {
      if (this.LocationsItems[i].locationUrl.toLowerCase() === item.Url.toLowerCase()) {
        this.currentActiveDot = this.LocationsItems[i];
        break;
      }
    }
    if (this.currentActiveDot) {
      this.currentActiveDot.isAnimate = true;
    }
  }

  stopAnimate() {
    if (this.currentActiveDot) {
      this.currentActiveDot.isAnimate = false;
    }
  }

  public scollToTop() {
    this._scrollToService.scrollTo(this.scrollToTop);
  }
}
