import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class ScrollToTopService {

  isBrowser;

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public scrollToTop() {
    setTimeout(() => {
      if (this.isBrowser) {
        window.scrollTo(0, 0);
      }
    });
  }
}
